import React, { useState, useEffect } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import useReactRouter from "use-react-router";


/**
 *
 * @Library
 *
 */
import { toast } from "react-toastify";
import { Formik } from 'formik'
import { useSelector } from 'react-redux'
/**
 *
 * @Component
 *
 */
import Loading from "../../../common/Loading";
import { CustomContainer, TableHeader, CustomButton } from "../../../common";
import { valiDate } from "../../../common/super";
import { CLASSROOM_MASTERS, CREATE_CLASSROOM } from "../../../apollo/classRoom/index";
import DeleteClassRoomConfirm from "./DeleteClassRoomConfirm"


import { Modal, Form } from 'react-bootstrap'
import EditClassRoom from "./EditClassRoom";
import { DEPARTMENTS, FACULTIES } from "../apollo/query";
import { MAJORS } from "../../../apollo/major";
import PaginationHelper from "../../../helpers/PaginationHelper";


function SettingClassRoom() {
  const accessmentRedux = useSelector((state) => state.accessment.value)
  const { history } = useReactRouter();
  const [dataUser, setDataUser] = useState({})

  const [showAdd, setShowAdd] = useState(false)
  const [facultyId, setFacultyId] = useState("")
  const [deparmentId, setDepartmentId] = useState("")
  const [departmentData, setDepartmentData] = useState([])
  const [majorData, setMajorData] = useState([])
  // const [majorId, setMajorId] = useState("")
  const [classRoomsData, setClassroomsData] = useState([])
  const [classRoomData, setClassroomData] = useState([])
  const [totalClassRoom, setTotalClassRoom] = useState(0);

  // edit data ================
  const [showEditClassRoom, setShowEditClassRoom] = useState(false)
  const [deleteClassRoomConfirmModal, setDeleteClassRoomConfirmModal] = useState(false)
  const [newData, setnewData] = useState()
 
  const { _limit, _skip, Pagination_helper } = PaginationHelper();

  const [createClassroom] = useMutation(CREATE_CLASSROOM)

  const [ loadClassRoomMasters, { loading: classRoomMastersLoading, data: classRoomMastersData },
  ] = useLazyQuery(CLASSROOM_MASTERS, { fetchPolicy: "network-only" });

  const [loadFaculty, { data: facultyDatas }] = useLazyQuery(FACULTIES);
  const [loadDepartment, { data: dapartmentApollo }] = useLazyQuery(DEPARTMENTS);
  const [loadMajor, { data: majorApollo }] = useLazyQuery(MAJORS);

  // on first load
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    if(user) {
      setDataUser(user?.data)
      if(user?.data?.role === 'ADMIN') {
        loadClassRoomMasters({
          variables: {
            skip: (_skip - 1) * _limit,
            first: _limit,
            orderBy: "createdAt_DESC"
          }
        });
      }
      if(user?.data?.role === 'FAC_ADMIN') {
        setFacultyId(user?.data?.faculty?.id)
        loadClassRoomMasters({
          variables: {
            where: { faculty: user?.data?.faculty?.id },
            skip: (_skip - 1) * _limit,
            first: _limit,
            orderBy: "createdAt_DESC"
          }
        })
      }
    }

    loadFaculty({
      variables: {
        where: {
          isDeleted: false,
          assessmentYear: accessmentRedux
        }
      }
    });
  }, [accessmentRedux]);

  useEffect(() => {
    if (dapartmentApollo) {
      setDepartmentData(dapartmentApollo?.departments)
    }
    if (majorApollo) {
      setMajorData(majorApollo?.majors?.data)
    }
  }, [dapartmentApollo, majorApollo])

  useEffect(() => {
    if(facultyId) {
      loadDepartment({
        variables: {
          where: {
            isDeleted: false,
            assessmentYear: accessmentRedux,
            faculty: facultyId
          }
        }
      })
    }
  }, [facultyId])

  useEffect(() => {
    if(classRoomMastersData?.classRooms?.data) setClassroomsData(classRoomMastersData?.classRooms?.data)
    setTotalClassRoom(classRoomMastersData?.classRooms?.total);
  }, [classRoomMastersData])
  

  const _handleEditClassRoomClose = () => setShowEditClassRoom(false)
  const _handleEditClassRoomShow = e => {
    setnewData(e)
    setShowEditClassRoom(true)
  }

  const _saveClassRoom = async (data) => {
    await createClassroom({
      variables: {
        data: { ...data }
      }
    })
    .then(async () => {
      setShowAdd(false)
      toast.success("ເພີ່ມຫ້ອງສຳເລັດ", {
        autoClose: 2000
      })
      loadClassRoomMasters();
    })
    .catch(err => {
      if(err?.message === "GraphQL error: THIS_ROOM_NAME_ALREADY_EXISTS") {
        toast.error("ຊື່ຫ້ອງນີ້ມີຢູ່ແລ້ວ!", {
          autoClose: 5000,
        });
      }
    })
  }

  const _closeAdd = () => setShowAdd(false)

  // delete
  const _handleDeleteClassRoom = (e) => {
    setClassroomData(e)
    setDeleteClassRoomConfirmModal(true)
  }

  const _handleDeleteClassRoomModalClose = () => {
    setDeleteClassRoomConfirmModal(false)
  }

  const _onSelectFaculty = (e) => {
    setFacultyId(e.target.value)
    if (e.target.value === "") {
      setDepartmentData([])
      setMajorData([])
    } else {
      setMajorData([])
    }
  }

  const _onSelectDeparment = (e) => {
    setDepartmentId(e.target.value)
    if (e.target.value === "") {
      setMajorData([])
    } else {
      loadMajor({
        variables: {
          where: {
            isDeleted: false,
            department: e.target.value
          }
        }
      })

    }
  }

  return (
    <div>
      <CustomContainer>
        <div className="row" style={{ marginTop: 0, marginBottom: 0 }}>
          <div className="col-md-10">
            <strong>ລາຍການຫ້ອງຮຽນ</strong>
          </div>
          <div className="col-md-2">
            <button
              className="btn btn-info right"
              // data-toggle="modal"
              // data-target="#addClassRoom"
              onClick={setShowAdd}
            >
              <i className="fa fa-plus"></i> ເພີ່ມຫ້ອງ
            </button>
          </div>
          {classRoomMastersLoading && <Loading />}
          <table
            className="table-hover"
            bordercolor="#fff"
            style={{ width: "100%" }}
          >
            <thead>
              <TableHeader>
                <th style={{ width: 60 }}>ລຳດັບ</th>
                <th>ຊື່ຫ້ອງນັກສຶກສາ</th>
                <th>ຊື່ຄະນະ</th>
                <th>ຊື່ພາກວິຊາ</th>
                <th>ຊື່ສາຂາວິຊາ</th>
                <th>ປີ</th>
                <th>ໝາຍເຫດ</th>
                <th style={{ width: 200 }}>ຈັດການ</th>
              </TableHeader>
            </thead>
            <tbody>
              {classRoomsData?.map((item, index) => (
                <tr
                  key={index}
                  style={{ border: "2px solid #ffffff", textAlign: "center", background: "#f0f0f0"}}
                >
                  <td>{index + 1 + _limit * (_skip - 1)}</td>
                  <td className="left">{item?.name || "-"}</td>
                  <td className="left">{item?.faculty?.name || "-"}</td>
                  <td className="left">{item?.department?.name || "-"}</td>
                  <td className="left">{item?.major?.name || "-"}</td>
                  <td className="left">{item?.year || "-"}</td>
                  <td className="center">{item?.note || "-"}</td>
                  <td>
                    <button
                      className="btn btn-control"
                      onClick={() => _handleEditClassRoomShow(item)}
                    >
                      <i className="fa fa-pen" style={{ color: "#28a745" }}></i>
                    </button>
                    &nbsp;&nbsp;
                    <button className="btn btn-control" onClick={() => _handleDeleteClassRoom(item)}>
                      <i
                        className="fa fa-trash"
                        style={{ color: "#B80000" }}
                      ></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
          <br/>
        {Pagination_helper(totalClassRoom, '/setting-master-data/class-room')}
      </CustomContainer>

      <Formik
        enableReinitialize
        initialValues={{
          faculty: facultyId ? facultyId : '',
          department: '',
          major: '',
          name: '',
          year: '',
          note: ''
        }}
        validate={values => {
          const errors = {}
          if (!values.faculty) {
            errors.faculty = 'ກະລຸນາເລືອກຄະນະ'
          }
          if (!values.department) {
            errors.department = 'ກະລຸນາເລືອກພາກ'
          }
          if (!values.major) {
            errors.major = 'ກະລຸນາເລືອກສາຂາ'
          }
          if (!values.name) {
            errors.name = 'ກະລຸນາປ້ອນຊື່ຫ້ອງນັກສຶກສາ'
          }
          if (!values.year) {
            errors.year = 'ກະລຸນາເລືອກປີ'
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          let data = {
            faculty: values.faculty,
            department: values.department,
            major: values.major,
            name: values.name,
            year: values.year,
            note: values.note,
          }
          _saveClassRoom(data)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
          /* and other goodies */
        }) => (
          <Modal
            animation={false}
            size='lg'
            centered
            show={showAdd}
            onHide={_closeAdd}
            style={{ padding: 0 }}
          >
            <Modal.Header style={{ padding: 10, border: 0 }}>
              <Modal.Title
                style={{
                  textAlign: 'center',
                  width: '100%',
                  padding: 0,
                  marginTop: 20,
                  fontWeight: 'bolder'
                }}
              >
                <strong>ເພີ່ມຫ້ອງຮຽນ</strong>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0 100px 20px 100px',
                justifyContent: 'center'
              }}
            >
              <label>ຄະນະ :{valiDate()}</label>
              <Form.Control
                as='select'
                name='faculty'
                onChange={(e) => {
                  handleChange(e)
                  _onSelectFaculty(e)
                }}
                value={values.faculty}
                isInvalid={!!errors.faculty}
                disabled={dataUser?.role === "FAC_ADMIN" || dataUser?.role === "DEP_ADMIN" ? true : false}
              >
                <option value={""}>---ກະລຸນາເລືອກຄະນະ---</option>
                {
                  facultyDatas?.faculties.map((fac, index) => (
                    <option key={"faculty" + index} value={fac?.id}>{fac?.name}</option>
                  )
                  )
                }
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.faculty}
              </Form.Control.Feedback>
              <label>ພາກວິຊາ :{valiDate()}</label>
              <Form.Control
                as='select'
                name='department'
                onChange={(e) => {
                  handleChange(e)
                  _onSelectDeparment(e)
                }}
                value={values.department}
                isInvalid={!!errors.department}
              >
                <option value={""}>---ກະລຸນາເລືອກພາກວິຊາ---</option>
                {departmentData.map((dep, index) => (
                  <option key={"department" + index} value={dep?.id}>{dep?.name}</option>
                )
                )}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.department}
              </Form.Control.Feedback>
              <label>ສາຂາວິຊາ :{valiDate()}</label>
              <Form.Control
                as='select'
                name='major'
                onChange={(e) => {
                  handleChange(e)
                  // _onSelectMajor(e)
                }}
                value={values.major}
                isInvalid={!!errors.major}
              >
                <option value={""}>---ກະລຸນາເລືອກສາຂາ---</option>
                {majorData.map((major, index) => (
                  <option key={"major" + index} value={major?.id}>{major?.name}</option>
                )
                )}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.major}
              </Form.Control.Feedback>
              <label>ຊື່ຫ້ອງນັກສຶກສາ :{valiDate()}</label>
              <Form.Control
                type='text'
                name='name'
                onChange={handleChange}
                value={values.name}
                isInvalid={!!errors.name}
                placeholder='ກະລຸນາປ້ອນຫ້ອງນັກສຶກສາ'
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
              <label>ປີ :{valiDate()}</label>
              <Form.Control
                as='select'
                name='year'
                onChange={handleChange}
                value={values.year}
                isInvalid={!!errors.year}
              >
                <option disabled={true} value="">---ກະລຸນາເລືອກປີ---</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.year}
              </Form.Control.Feedback>
              <label>ໝາຍເຫດ</label>
              <textarea
                className='form-control'
                name='note'
                onChange={handleChange}
                value={values.note}
                placeholder='ກະລຸນາປ້ອນໝາຍເຫດ(ຖ້າມີ)'
              />
            </Modal.Body>
            <Modal.Footer>
              <CustomButton
                title="ຍົກເລີກ"
                onClick={_closeAdd}
              />
              <CustomButton
                onClick={handleSubmit}
                title="ບັນທຶກ"
                type="submit"
                confirm
              />
            </Modal.Footer>
          </Modal>

        )}
      </Formik>

      {/* update faculty */}
      <EditClassRoom
        showEditClassRoom={showEditClassRoom}
        _handleEditClassRoomClose={_handleEditClassRoomClose}
        let
        allData={newData}
      />

      {/* delete className */}
      <DeleteClassRoomConfirm
        deleteClassRoomConfirmModal={deleteClassRoomConfirmModal}
        _handleDeleteClassRoomConfirmModalClose={_handleDeleteClassRoomModalClose}
        classRoomData={classRoomData}
      />

    </div>
  );
}
export default SettingClassRoom;
