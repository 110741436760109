import React, { useState, useEffect } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";

/**
 *
 * @Library
 *
 */
import { Formik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/**
 *
 * @Component
 *
 */
import { CustomButton, CustomContainer, TableHeader } from "../../../common";
import { Modal, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from 'react-redux'
import UpgradeNav from "../common/UpgradeNav";
import Loading from "../../../common/Loading";

/**
 *
 * @Constant
 *
 */
import Consts from "../../../consts";

/**
 *
 * @Apollo
 *
 */
import { APPROVE_GRADE } from "../../../apollo/deparment/index";
import { GRADES_FOR_UPGRADE } from "../../../apollo/studentResisterUpgrade";
import { COURSES } from "../../../apollo/course/index";
import { TEACHERS } from "../../../apollo/user/index";
/**
 *
 * @Function
 *
 */

import "./style.css";
import { setGenders } from "../../../common/super";

export default function StudenRegisterUpgrade() {

  const accessmentRedux = useSelector((state) => state.accessment.value)
  const user = JSON.parse(window.localStorage.getItem("user")).data;
  const ROLE = user?.role;
  const [factId, setFactId] = useState();
  const [departmentId, setDepartmentId] = useState();
  // const [selectCourse, setSelectCourse] = useState();
  const [selectedYear, setSelectedYear] = useState();
  const [selectSemester, setSelectSemester] = useState();
  const [selectTeacher, setSelectTeacher] = useState();
  const [gradesForUpgrade, setGradesForUpgrade] = useState([]);

  const [dataStudents, setDataStudents] = useState();
  const [show, setShow] = useState(false);
  const handleClose = (e) => setShow(false);
  const handleShow = (e) => setShow(true);
  const [approveGrade] = useMutation(APPROVE_GRADE);

  // Query GradeImproves
  const [loadGrades, { loading: loadingGrades, data: dataGrades }] = useLazyQuery(GRADES_FOR_UPGRADE);
  // Qeury teacher
  const [loadTeacher, { data: teacherData }] = useLazyQuery(TEACHERS);
  // Qeury course
  const [loadCourse, { data: courseData }] = useLazyQuery(COURSES);

  useEffect(() => {
    let _whereCourse = { assessmentYear: accessmentRedux }
    if (user?.faculty?.id !== "") _whereCourse = { ..._whereCourse, faculty: user?.faculty?.id }
    if (user?.department?.id !== "") _whereCourse = { ..._whereCourse, department: user?.department?.id }
    loadCourse({
      variables: {
        where: _whereCourse,
      },
    });
  }, [factId, departmentId, accessmentRedux]);

  useEffect(() => {

    let courseWhere = { isDeleted: false, assessmentYear: accessmentRedux }
    if (user?.faculty?.id != "") courseWhere = { ...courseWhere, faculty: user?.faculty?.id }
    if (user?.department?.id != "") courseWhere = { ...courseWhere, department: user?.department?.id }
    loadCourse({
      variables: {
        where: courseWhere,
      },
    });

    let teacherWhere = { isDeleted: false }
    if (factId !== "") teacherWhere = { ...teacherWhere, faculty: factId }
    if (departmentId !== "") teacherWhere = { ...teacherWhere, department: departmentId }

    loadTeacher({
      variables: {
        where: teacherWhere,
      },
    });

    loadGrades({
      variables: {
        where: {
          assessmentYear: accessmentRedux,
          gredeStatus_in: ["REQUESTING", "APROVED"],
          numberTest_in: [2, 3, 4, 5],
        },
      },
    });

    setFactId(ROLE !== "ADMIN" ? user?.faculty?.id : "");
    setDepartmentId(ROLE === "DEP_ADMIN" ? user?.department?.id : "");
  }, []);
  
  useEffect(() => {
    if(dataGrades?.gradesForUpgrade?.data) {
      setGradesForUpgrade(dataGrades?.gradesForUpgrade?.data)
    }
  }, [dataGrades])
  
  // load GradeImproves
  useEffect(() => {
    let allWhere = {
      numberTest_in: [2, 3, 4, 5],
      gredeStatus_in: ["REQUESTING", "APROVED"],
      assessmentYear: accessmentRedux
    };
    // if (selectCourse) allWhere = { ...allWhere, course: selectCourse };
    if (selectedYear) allWhere = { ...allWhere, yearLevel: parseInt(selectedYear) };
    if (selectSemester) allWhere = { ...allWhere, semester: parseInt(selectSemester) };
    if (selectTeacher) allWhere = { ...allWhere, teacher: selectTeacher };

    loadGrades({
      variables: {
        where: allWhere,
      },
    });
  }, [selectedYear, selectSemester, selectTeacher]);

  // select yaer
  const _onSelectedYear = (e) => {
    setSelectedYear(e.target.value);
  };
  // select unit
  const _onSelectedSemester = (e) => {
    setSelectSemester(e.target.value);
  };
  // select Teacher
  const _onSelectedTeacher = (e) => {
    setSelectTeacher(e.target.value);
  };
  // select Course
  // const _onSelectCourse = (e) => {
  //   setSelectCourse(e.target.value);
  // };
  // click
  const _handleDataStudent = (StNumber) => {
    setDataStudents(StNumber);
  };

  const _upgradeGrade = async (values) => {
    await approveGrade({
      variables: { where: { id: values.id } },
    });

    loadGrades({
      variables: {
        where: {
          assessmentYear: accessmentRedux,
          numberTest_in: [2, 3, 4, 5],
          gredeStatus_in: ["REQUESTING", "APROVED"],
        },
      },
    });
  };

  const _handleSearchFromStudent = () => {
    try {
      let allWhere = {
        numberTest_in: [2, 3, 4, 5],
        gredeStatus_in: ["REQUESTING", "APROVED"],
        assessmentYear: accessmentRedux
      };
      // if (selectCourse) allWhere = { ...allWhere, course: selectCourse };
      if (selectedYear) allWhere = { ...allWhere, yearLevel: parseInt(selectedYear) };
      if (selectSemester) allWhere = { ...allWhere, semester: parseInt(selectSemester) };
      if (selectTeacher) allWhere = { ...allWhere, teacher: selectTeacher };
  
      loadGrades({
        variables: {
          where: allWhere,
        },
      });
    } catch (error) {
      console.log("error: ", error)
    }
  }

  return (
    <div>
      {loadingGrades && <Loading />}
      <UpgradeNav />
      <CustomContainer>
        <div className="form-group row">
          <label className="col-sm-1 col-form-label">ຄົ້ນຫາ</label>
          <div className="col-sm-9">
            <input
              type="text"
              className="form-control boxs"
              id="searchtext"
              placeholder="ປ້ອນລະຫັດວິຊາ"
              // onChange={(e) => setSelectCourse(e.target.value)}
            />
          </div>
          <div className="col-sm-2">
            <CustomButton
              confirm
              searchIcon
              width={50}
              height={38}
              onClick={() => _handleSearchFromStudent()}
            />
          </div>
        </div>

        {/* choices filter data */}
        <div className="row">
          {/* <div className="col-lg-4 col-md-5 col-sm-7">
            <label>ວິຊາ</label>
            <select
              className="form-control"
              onChange={(e) => _onSelectCourse(e)}
            >
              <option value="">ທັງໝົດ</option>
              {courseData?.course_page?.data?.map((item, index) => {
                return (
                  <option key={index} value={item?.title}>
                    {item?.title}
                  </option>
                );
              })}
            </select>
          </div> */}

          <div className="col-lg-3 col-md-4 col-sm-7">
            <label>ປີ</label>
            <select
              className="form-control"
              onChange={(e) => _onSelectedYear(e)}
            >
              <option value="">ເລືອກປີ</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
            </select>
          </div>

          <div className="col-lg-3 col-md-5 col-sm-7">
            <label>ພາກຮຽນ</label>
            <select
              className="form-control"
              onChange={(e) => _onSelectedSemester(e)}
            >
              <option value="">ເລືອກພາກ</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
            </select>
          </div>
          {/* <div className="col-lg-2 col-md-4 col-sm-6">
            <label>ສົກຮຽນ</label>
            <select
              className="form-control"
              onChange={(e) => _onSelectAccessMent(e)}
            >
              <option value="">ເລືອກສົກຮຽນ</option>
              {callAccessMent?.map((item, index) => {
                return (
                  <option key={index} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div> */}

          <div className="col-lg-4 col-md-5 col-sm-7">
            <label>ອາຈານ</label>
            <select
              className="form-control"
              onChange={(e) => _onSelectedTeacher(e)}
            >
              <option value="">ເລືອກອາຈານ</option>
              {teacherData?.user_page?.data.map((item, index) => (
                <option key={"teacher" + index} value={item?.id}>
                  {item?.firstname}
                </option>
              ))}
            </select>
          </div>
        </div>

        <table border="1" style={{ width: "100%" }}>
          <thead style={{ color: "#fff" }}>
            <TableHeader>
              <th style={{ width: 60 }}>ລຳດັບ</th>
              <th>ລະຫັດນັກສຶກສາ</th>
              <th>ຊື່ນັກສຶກສາ</th>
              <th>ນັກສຶກສາປີ</th>
              <th style={{ width: 80 }}>ພາກຮຽນ</th>
              <th>ສົກຮຽນ</th>
              <th>ລະຫັດວິຊາ</th>
              <th>ຊື່ວິຊາ</th>
              <th>ຊື່ອາຈານປະຈຳວິຊາ</th>
              <th>ເກຣດເກົ່າ</th>
              <th style={{ width: 100 }}>ຈັດການ</th>
            </TableHeader>
          </thead>
          <tbody>
            {gradesForUpgrade?.map((dataUpgrade, index) => {
              return (
                <tr key={index}>
                  <td className="center"> {index + 1}</td>
                  <td>{dataUpgrade?.student?.studentNumber}</td>
                  <td>
                    {setGenders(dataUpgrade?.student?.gender) +
                      " " +
                      dataUpgrade?.student?.firstNameL}{" "}
                    {dataUpgrade?.student?.lastNameL}
                  </td>
                  <td className="center">{dataUpgrade?.yearLevel}</td>
                  <td className="center">{dataUpgrade?.semester}</td>
                  <td className="center">
                    {dataUpgrade?.student?.assessmentYear}
                  </td>
                  <td>{dataUpgrade?.course?.courseCode}</td>
                  <td>{dataUpgrade?.course?.title}</td>
                  <td>
                    {dataUpgrade?.course?.teacher?.map(
                      (_teacher) => _teacher?.firstname + " " + _teacher?.lastname
                    )}
                  </td>
                  <td className="center">{dataUpgrade?.letterGrade}</td>
                  <td>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-disabled">ເບິ່ງລາຍລະອຽດ</Tooltip>
                      }
                    >
                      <div
                        style={{
                          textAlign: "center",
                          cursor: "pointer",
                          backgroundColor: "#FFFFFF",
                          padding: 3,
                          width: 50,
                          borderRadius: 4,
                        }}
                        onClick={(e) => {
                          handleShow(e);
                          return _handleDataStudent(dataUpgrade);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={["fas", "eye"]}
                          color={Consts.BORDER_COLOR}
                        />
                      </div>
                    </OverlayTrigger>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {/* Modal */}
        <Modal
          show={show}
          onHide={() => setShow(false)}
          size="lg"
          style={{ marginLeft: 60 }}
        >
          <Modal.Header>
            <Modal.Title className="text-center">
              ກວດສອບການລົງທະບຽນແກ້ເກຣດ
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                id: dataStudents?.id,
              }}
              onSubmit={(values) => {
                _upgradeGrade(values);
              }}
            >
              {({ handleSubmit }) => (
                <div>
                  <CustomContainer>
                    {/* Table list */}
                    <div>
                      <Col sm={12}>
                        <div className="heading">
                          <b>ວິຊາ</b>
                        </div>
                      </Col>
                      <div className="list">
                        <Col sm={3} style={{ padding: 20 }}>
                          ຊື່ ແລະ ນາມສະກຸນ:
                        </Col>
                        <Col sm={6} style={{ padding: 20, fontWeight: "bold" }}>
                          {dataStudents?.student?.firstNameL}{" "}
                          {dataStudents?.student?.lastNameL}
                        </Col>
                        <Col
                          sm={2}
                          style={{ padding: 20, fontWeight: "bold" }}
                        ></Col>
                        <Col
                          sm={1}
                          style={{ padding: 20, fontWeight: "bold" }}
                        ></Col>
                      </div>
                      <div className="list">
                        <Col sm={3} style={{ padding: 20 }}>
                          ຊື່ວິຊາແກ້ເກຣດ:
                        </Col>
                        <Col sm={6} style={{ padding: 20, fontWeight: "bold" }}>
                          {dataStudents?.course?.title}
                        </Col>
                        <Col
                          sm={2}
                          style={{ padding: 20, fontWeight: "bold" }}
                        ></Col>
                        <Col
                          sm={1}
                          style={{ padding: 20, fontWeight: "bold" }}
                        ></Col>
                      </div>
                      <div className="list">
                        <Col sm={3} style={{ padding: 20 }}>
                          ຊື່ອາຈານປະຈຳວິຊາ:
                        </Col>
                        <Col sm={6} style={{ padding: 20, fontWeight: "bold" }}>
                          {" "}
                          {dataStudents?.course?.teacher?.map((item, i) => (
                            <span key={i}>
                              {item.firstname +
                                (i < dataStudents?.course?.teacher.length - 1
                                  ? ",   "
                                  : " ")}
                            </span>
                          ))}{" "}
                        </Col>
                      </div>
                      <div className="list">
                        <Col sm={3} style={{ padding: 20 }}>
                          ສົກຮຽນ:
                        </Col>
                        <Col sm={6} style={{ padding: 20, fontWeight: "bold" }}>
                          {" "}
                          {dataStudents?.student?.assessmentYear}{" "}
                        </Col>
                      </div>
                      <div className="list">
                        <Col sm={3} style={{ padding: 20 }}>
                          ເກຣດເດີມ:
                        </Col>
                        <Col sm={6} style={{ padding: 20, fontWeight: "bold" }}>
                          {" "}
                          {dataStudents?.letterGrade}{" "}
                        </Col>
                      </div>
                    </div>
                  </CustomContainer>

                  <div className="text-right">
                    <button
                      type="button"
                      className="btn addbtnreset"
                      onClick={() => handleClose()}
                    >
                      ຍົກເລີກ
                    </button>
                    {dataStudents?.gredeStatus === "APROVED" ? (
                      ""
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-secondary"
                        style={{ backgroundColor: "#057CAE", color: "#ffffff" }}
                        onClick={() => {
                          handleSubmit();
                          handleClose();
                        }}
                      >
                        ອານຸມັດແກ້ເກຣດ
                      </button>
                    )}
                  </div>
                </div>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      </CustomContainer>
    </div>
  );
}
