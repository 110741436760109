import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";

/**
 *
 * @Library
 *
 */
import { Formik } from "formik";
import * as _ from "lodash";


/**
 *
 * @Component
 *
 */
import { Breadcrumb, Form, Row, Col } from "react-bootstrap";
import { CustomContainer } from "../../common";

/**
 *
 * @Constant
 *
 */
import Consts from "../../consts";
import Routers from "../../consts/router";

/**
 *
 * @Function
 *
 */
import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from "@mui/material";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import StepperProgress from "./common/StepperProgress";
import { formatDateDash } from "../../common/super";


function TeacherFormStatistic() {
  const { history, location } = useReactRouter();
  const teacherDataById = location?.state
  const [dataFromStored, setDataFromStored] = useState();
  const [isInitialCheckDone, setIsInitialCheckDone] = useState(false);

  useEffect(() => {
    // Check for stored form values in localStorage
    const storedValues = localStorage.getItem('statisticInfo');
    if (storedValues) {
      const parsedValues = JSON.parse(storedValues);
      setDataFromStored(parsedValues);
    }
    setIsInitialCheckDone(true);
  }, []);

  useEffect(() => {
    // Only set dataFromStored to teacherDataById if the initial check is done
    // and dataFromStored is still undefined
    if (isInitialCheckDone && dataFromStored === undefined) {
      if (teacherDataById !== undefined) {
        setDataFromStored(teacherDataById);
      } else {
        setDataFromStored();
      }
    }
  }, [teacherDataById, dataFromStored, isInitialCheckDone]);

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item
          href=""
          onClick={() => history.push(Routers.TEACHER_LIST + Routers.PAGE_GINATION)}
        >
          ຈັດການອາຈານ
        </Breadcrumb.Item>
        <Breadcrumb.Item
          href=""
          onClick={() => history.push(Routers.TEACHER_LIST + Routers.PAGE_GINATION)}
        >
          ອາຈານທັງໝົດ
        </Breadcrumb.Item>
        <Breadcrumb.Item active>ເພີ່ມອາຈານ</Breadcrumb.Item>
      </Breadcrumb>

      <CustomContainer>
        <StepperProgress />

        <Formik
          enableReinitialize={true}
          initialValues={{
            level: dataFromStored?.level,
            positionStatus: dataFromStored?.positionStatus || "",
            staffType: dataFromStored?.staffType || "",
            degree: dataFromStored?.degree || "",
            dateStartWork: dataFromStored?.dateStartWork !== undefined ? formatDateDash(dataFromStored?.dateStartWork) : dataFromStored?.dateStartWork,
            secondaryYear: dataFromStored?.secondaryYear || "",
            educationDegree: dataFromStored?.educationDegree || "",
            graduatedMajor: dataFromStored?.graduatedMajor || "",
            startDateEnchance: dataFromStored?.startDateEnchance !== undefined ? formatDateDash(dataFromStored?.startDateEnchance) : dataFromStored?.startDateEnchance,
            endDateEnchance: dataFromStored?.endDateEnchance !== undefined ? formatDateDash(dataFromStored?.endDateEnchance) : dataFromStored?.endDateEnchance,
            graudatedCountry: dataFromStored?.graudatedCountry || "",
            enchanceDegree: dataFromStored?.enchanceDegree || "",
            startDateAcademic: dataFromStored?.startDateAcademic !== undefined ? formatDateDash(dataFromStored?.startDateAcademic) : dataFromStored?.startDateAcademic,
            positionAdminister: dataFromStored?.positionAdminister || "",
            positionAdministerName: dataFromStored?.positionAdministerName || "",
            positionAdministerNameDate: dataFromStored?.positionAdministerNameDate !== undefined ? formatDateDash(dataFromStored?.positionAdministerNameDate) : dataFromStored?.positionAdministerNameDate,
            positionOffice: dataFromStored?.positionOffice || "",
            civilLecture: dataFromStored?.civilLecture || "",
            dateFamilyFollow: dataFromStored?.dateFamilyFollow !== undefined ? formatDateDash(dataFromStored?.dateFamilyFollow) : dataFromStored?.dateFamilyFollow,
            dateFamilyFollowBack: dataFromStored?.dateFamilyFollowBack !== undefined ? formatDateDash(dataFromStored?.dateFamilyFollowBack) : dataFromStored?.dateFamilyFollowBack,
            followCountry: dataFromStored?.followCountry || "",
            positionRemark: dataFromStored?.positionRemark || "",
          }}
          validate={(values) => {
            // const errors = {};
            // if (!values.faculty) {
            //   errors.faculty = "ກະລຸນາເລືອກຄະນະ"
            // }
            // }
            // return errors;
          }}
          onSubmit={(values) => {
            const formValues = JSON.stringify(values);
            localStorage.setItem('statisticInfo', formValues);
            history.push(Routers.TEACHER_FORM_PLACE_OF_BIRTH, teacherDataById)
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            /* and other goodies */
          }) => (
            <div>
              {/* Form container */}
              <div
              // style={{
              //   width: "80%",
              //   marginLeft: "auto",
              //   marginRight: "auto",
              // }}
              >
                <Form noValidate>
                  {/* ---------- ຂໍ້ມູນການສຶກສາ --------- */}
                  <div style={{ marginBottom: 10 }}>
                    <div className="heading"><b>ຂໍ້ມູນການສຶກສາ</b></div>
                    {/* ພາກວິຊາ */}
                    <Row className="mb-1">
                      <Form.Group as={Col}>
                        <Form.Label>ເລືອກປະເພດຕາມຊັ້ນ</Form.Label>
                        <Form.Control
                          as='select'
                          name='level'
                          value={values.level}
                          onChange={handleChange}
                        >
                          <option value="">ເລືອກປະເພດຕາມຊັ້ນ</option>
                          <option value='specialLevel'>ລະດັບຊັ້ນວິຊາສະເພາະ</option>
                          <option value='teachLevel'>ລະດັບຊັ້ນສອນ</option>
                        </Form.Control>
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>ສະຖານະ</Form.Label>
                        <Form.Control
                          as='select'
                          name="positionStatus"
                          value={values.positionStatus}
                          onChange={handleChange}
                        >
                          <option value="">ເລືອກສະຖານະພາບ</option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                          <option value={6}>6</option>
                          <option value={7}>7</option>
                          <option value={8}>8</option>
                          <option value={9}>9</option>
                        </Form.Control>
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>ປະເພດພະນັກງານ</Form.Label>
                        <Form.Control
                          as='select'
                          type="text"
                          name="staffType"
                          value={values.staffType}
                          onChange={handleChange}
                        // isInvalid={!!errors.degree}
                        >
                          <option value="">ເລືອກປະເພດພະນັກງານ</option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                          <option value={6}>6</option>
                          <option value={7}>7</option>
                          <option value={8}>8</option>
                        </Form.Control>
                      </Form.Group>
                    </Row>

                    <Row className="mb-1">
                      <Form.Group as={Col}>
                        <Form.Label>ສຶກສາສາມັນ</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="ມ 6"
                          name="secondaryYear"
                          value={values.secondaryYear}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>ວຸດທິການສຶກສາ</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="ຮສ"
                          name="educationDegree"
                          value={values.educationDegree}
                          onChange={handleChange}
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>ສາຂາວິຊາຈົບ</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="ບໍລິຫານທຸລະກິດ"
                          name="graduatedMajor"
                          value={values.graduatedMajor}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-1">
                      <Form.Group as={Col}>
                        <Form.Label>ວັນທິີເດືອນປີ ຍົກລະດັບການສຶກສາ</Form.Label>
                        <Form.Control
                          type="date"
                          name="startDateEnchance"
                          value={values.startDateEnchance}
                          onChange={handleChange}
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>ວັນທີເດືອນປີ ສຳເລັດການສຶກສາ</Form.Label>
                        <Form.Control
                          type="date"
                          name="endDateEnchance"
                          value={values.endDateEnchance}
                          onChange={handleChange}
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>ສັງກັດລັດ</Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="ກະລຸນາເລືອກວັນທີສັງກັດລັດ"
                          name="dateStartWork"
                          value={values.dateStartWork}
                          onChange={handleChange}
                        />
                      </Form.Group>

                    </Row>
                    <Row className="mb-1">
                      <Form.Group as={Col}>
                        <Form.Label>ຈົບຈາກປະເທດ</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="ລາວ"
                          name="graudatedCountry"
                          value={values.graudatedCountry}
                          onChange={handleChange}
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>ຮຽນຕໍ່ລະດັບ ປ... ສາຂາວິຊາ</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="ລາວ"
                          name="enchanceDegree"
                          value={values.enchanceDegree}
                          onChange={handleChange}
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>ທິດສະດີການເມືອງ</Form.Label>
                        <Form.Control
                          type="text"
                          name="civilLecture"
                          value={values.civilLecture}
                          placeholder="ກະລຸນາປ້ອນນາມສະກຸນ"
                          onChange={handleChange}
                          isInvalid={!!errors.civilLecture}
                        />
                      </Form.Group>
                    </Row>

                    <Row>
                      <Form.Group as={Col}>
                        <Form.Label>ວັນເດືອນປີ ຕິດຕາມຄອບຄົວ</Form.Label>
                        <Form.Control
                          type="date"
                          placeholder=""
                          name="dateFamilyFollow"
                          value={values.dateFamilyFollow}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>ວັນເດືອນປີກັບມາຈາກການຕິດຕາມຄ/ຄ</Form.Label>
                        <Form.Control
                          type="date"
                          placeholder=""
                          name="dateFamilyFollowBack"
                          value={values.dateFamilyFollowBack}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>ປະເທດ</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="ລາວ"
                          name="followCountry"
                          value={values.followCountry}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Row>

                    <div className="heading"><b>ຂໍ້ມູນຕຳແໜງ</b></div>
                    <Row>
                      <Form.Group as={Col}>
                        <Form.Label>ຕຳແໜ່ງວິຊາການ</Form.Label>
                        <Form.Control
                          type="text"
                          name="degree"
                          value={values.degree}
                          placeholder="ຮສ.ດຣ, ປອ..."
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>ວັນເດືອນປີແຕ່ງຕັ້ງວິຊາການ</Form.Label>
                        <Form.Control
                          type="date"
                          name="startDateAcademic"
                          value={values.startDateAcademic}
                          onChange={handleChange}
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>ຕຳແໜ່ງບໍລິຫານ</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="1"
                          name="positionAdminister"
                          value={values.positionAdminister}
                          onChange={handleChange}
                        />
                      </Form.Group>

                    </Row>

                    <Row>
                      <Form.Group as={Col}>
                        <Form.Label>ຊື່ ຕຳແໜ່ງບໍລິຫານ</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="ຮອງຫົວໜ້າຫ້ອງການມະຫາວິທະຍາໄລ"
                          name="positionAdministerName"
                          value={values.positionAdministerName}
                          onChange={handleChange}
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>ວັນເດືອນປີແຕ່ງຕັ້ງຕໍາແໜ່ງບໍລິຫານ</Form.Label>
                        <Form.Control
                          type="date"
                          name="positionAdministerNameDate"
                          value={values.positionAdministerNameDate}
                          onChange={handleChange}
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>ພາກສ່ວນຫ້ອງການສຳນັກງານ</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="ຫ້ອງການມະຫາວິທະຍາໄລ"
                          name="positionOffice"
                          value={values.positionOffice}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Row>

                    <Row>
                      <Form.Group as={Col}>
                        <Form.Label>ໝາຍເຫດ</Form.Label>
                        <Form.Control
                          as="textarea"
                          style={{ height: "10px" }}
                          placeholder="ໝາຍເຫດ"
                          name="positionRemark"
                          value={values.positionRemark}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Row>
                  </div>

                </Form>
                {/* Buttons */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    textAlign: "center",
                    justifyContent: "end",
                    marginTop: 40,
                    marginBottom: 40,
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      marginRight: "30px", backgroundColor: "darkgray", '&:hover': {
                        backgroundColor: "gray"
                      }
                    }}
                    startIcon={<KeyboardDoubleArrowLeftIcon />}
                    onClick={() => history.goBack()}
                  >
                    ກັບຄືນ
                  </Button>
                  <Button
                    variant="contained"
                    endIcon={<KeyboardDoubleArrowRightIcon />}
                    onClick={handleSubmit}
                  >
                    ໜ້າຕໍ່ໄປ
                  </Button>

                </div>
              </div>
            </div>
          )
          }
        </Formik >
      </CustomContainer >
    </div >
  );
}

export default TeacherFormStatistic;
