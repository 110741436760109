import React, { useState, useEffect, useRef } from "react";
import useReactRouter from "use-react-router";

/**
 *
 * @Library
 *
 */
import { Formik } from "formik";
import { Input, Progress } from "reactstrap";
import * as _ from "lodash";
import { useSelector } from "react-redux";

/**
 *
 * @Component
 *
 */
import { Breadcrumb, Form, Row, Col } from "react-bootstrap";
import { CustomContainer, Title, CustomButton } from "../../common";

/**
 *
 * @Constant
 *
 */
import Consts from "../../consts";
import Routers, { COURSE_LIST } from "../../consts/router";
import { API_KEY, NEW_API_URL } from "../../common/contrant";

/**
 *
 * @Function
 *
 */
import { convertRole } from "../../helpers/user";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import StepperProgress from "./common/StepperProgress";
import axios from "axios";
import { formatDateDash } from "../../common/super";
import { RELIGIONAL_MASTERS } from "../../apollo/religionalMasters";
import { TRIBES } from "../preregistrationStudent/apollo/query";
import { useLazyQuery } from "@apollo/react-hooks";
import { COUNTRIES_LIST } from "../../consts/Countries";
import { ROLE_LIST } from "../../common/GetRoleDescription";

function TeacherAdd() {
  const { history, location } = useReactRouter();
  const accessmentRedux = useSelector((state) => state.accessment.value);
  const inputImage = useRef("inputImage");
  const teacherDataById = location?.state;

  // States
  const [dataFromStored, setDataFromStored] = useState();
  const [dataFaculties, setDataFaculties] = useState([]);
  const [dataDepartment, setDataDepartment] = useState([]);
  const [selectFacultyName, setSelectFacultyName] = useState("");
  const [selectDepartmentName, setSelectDepartmentName] = useState("");
  const [strengthPass, setStrengthPass] = useState(0);
  const [checkPasswordLength, setCheckPasswordLength] = useState("");
  const [previewImageURL, setPreviewImageURL] = useState("");
  const [isInitialCheckDone, setIsInitialCheckDone] = useState(false);
  const [selectTribeName, setSelectTribeName] = useState();
  const [selectReligionName, setSelectReligionName] = useState();

  const [loadTribes, { data: tribeData }] = useLazyQuery(TRIBES);
  const [loadReligionalMasters, { data: religionalMastersData }] =
    useLazyQuery(RELIGIONAL_MASTERS);

  useEffect(() => {
    const storedValues = localStorage.getItem("generalInfo");
    if (storedValues) {
      const parsedValues = JSON.parse(storedValues);
      setDataFromStored(parsedValues);
    }
    axios
      .get(NEW_API_URL + "faculty", {
        headers: {
          api_key: API_KEY,
        },
      })
      .then((response) => setDataFaculties(response.data?.data || []))
      .catch((error) => console.error(error));
    loadTribes();
    loadReligionalMasters();
    setIsInitialCheckDone(true);
  }, []);

  useEffect(() => {
    // Only set dataFromStored to teacherDataById if the initial check is done
    // and dataFromStored is still undefined
    if (isInitialCheckDone && dataFromStored === undefined) {
      if (teacherDataById !== undefined) {
        const preNewData = {
          ...teacherDataById,
          faculty: teacherDataById?.faculty?._id,
          department: teacherDataById?.department?._id,
        };
        setDataFromStored(preNewData);
      } else {
        setDataFromStored();
      }
    }
  }, [teacherDataById, dataFromStored, isInitialCheckDone]);

  useEffect(() => {
    if (dataFromStored?.tribeName) {
      setSelectTribeName(dataFromStored?.tribeName);
    } else if (tribeData != undefined) {
      const tribName = tribeData?.tribes.filter(
        (trib) => trib?.id === dataFromStored?.tribe
      );

      setSelectTribeName(tribName[0]?.trib_name);
    }
    if (dataFromStored?.religionName) {
      setSelectReligionName(dataFromStored?.religionName);
    } else if (religionalMastersData != undefined) {
      const religName = religionalMastersData?.religionalMasters?.data?.filter(
        (religion) => religion?.id === dataFromStored?.religion
      );

      setSelectReligionName(religName[0]?.name);
    }
  }, [dataFromStored, tribeData, religionalMastersData]);

  useEffect(() => {
    const facId = dataFromStored?.faculty;
    if (facId) {
      const facultyName = dataFaculties?.filter(
        (faculty) => faculty?._id === facId
      );
      setSelectFacultyName(facultyName[0]?.name);

      axios
        .get(NEW_API_URL + "department/" + facId, {
          headers: {
            api_key: API_KEY,
          },
        })
        .then((response) => setDataDepartment(response.data?.data || []))
        .catch((error) => console.error(error));
    }
  }, [dataFromStored?.faculty, dataFaculties]);

  useEffect(() => {
    if (dataFromStored?.department) {
      const departmentName = dataDepartment?.filter(
        (department) => department?._id === dataFromStored?.department
      );
      setSelectDepartmentName(departmentName[0]?.name);
    }
  }, [dataFromStored?.department, dataDepartment]);

  const onSelectFaculty = (e) => {
    const facultyId = e.target.value;
    if (facultyId != "") {
      const facultyName = dataFaculties.filter(
        (faculty) => faculty._id === facultyId
      );
      setSelectFacultyName(facultyName[0].name);
      axios
        .get(NEW_API_URL + "department/" + facultyId, {
          headers: {
            api_key: API_KEY,
          },
        })
        .then((response) => setDataDepartment(response.data?.data || []))
        .catch((error) => console.error(error));
    } else {
      setDataDepartment([]);
    }
  };
  const onSelectDepartment = (e) => {
    const departmentId = e.target.value;
    if (departmentId != "") {
      const departmentName = dataDepartment.filter(
        (department) => department._id === departmentId
      );
      setSelectDepartmentName(departmentName[0].name);
    }
  };

  const selectTribe = (id) => {
    const tribName = tribeData?.tribes.filter(
      (trib) => trib?.id === id?.target?.value
    );

    setSelectTribeName(tribName[0]?.trib_name);
  };

  const selectReligion = (id) => {
    const religName = religionalMastersData?.religionalMasters?.data?.filter(
      (religion) => religion?.id === id?.target?.value
    );

    setSelectReligionName(religName[0]?.name);
  };

  function checkPassword(password) {
    var strength = 0;
    if (password.match(/[a-z]+/)) {
      strength += 1;
    }
    if (password.match(/[A-Z]+/)) {
      strength += 1;
    }
    if (password.match(/[0-9]+/)) {
      strength += 1;
    }
    if (password.match(/[$@#&!%_?]+/)) {
      strength += 1;
    }

    switch (strength) {
      case 0:
        setStrengthPass(0);
        break;

      case 1:
        setStrengthPass(25);
        break;

      case 2:
        setStrengthPass(50);
        break;

      case 3:
        setStrengthPass(75);
        break;

      case 4:
        setStrengthPass(100);
        break;
      default: // Do nothing
    }
  }
  const checkLength = (password) => {
    if (password && password.length < 6) {
      setCheckPasswordLength("ກະລຸນາປ້ອນໜ້ອຍສຸດຢ່າງໜ້ອຍ 6 ໂຕຂຶ້ນໄປ");
    } else {
      setCheckPasswordLength("");
    }
  };

  const addImage = () => {
    inputImage.current.click();
  };

  const handleChangeImage = (event) => {
    if (event.target.files[0]) {
      setPreviewImageURL(URL.createObjectURL(event.target.files[0]));
      // setCoverImage(event.target.files[0]);
    }
  };

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item
          href=""
          onClick={() =>
            history.push(Routers.TEACHER_LIST + Routers.PAGE_GINATION)
          }
        >
          ຈັດການອາຈານ
        </Breadcrumb.Item>
        <Breadcrumb.Item
          href=""
          onClick={() =>
            history.push(Routers.TEACHER_LIST + Routers.PAGE_GINATION)
          }
        >
          ອາຈານທັງໝົດ
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          {teacherDataById === undefined ? "ເພີ່ມອາຈານ" : "ແກ້ໄຂອາຈານ"}
        </Breadcrumb.Item>
      </Breadcrumb>

      <CustomContainer>
        <StepperProgress />

        <Formik
          enableReinitialize={true}
          initialValues={{
            firstname: dataFromStored?.firstname || "",
            lastname: dataFromStored?.lastname || "",
            faculty: dataFromStored?.faculty,
            department: dataFromStored?.department,
            nationality: dataFromStored?.nationality,
            tribe: dataFromStored?.tribe || "",
            religion: dataFromStored?.religion || "",
            phone: dataFromStored?.phone || "",
            email: dataFromStored?.email || "",
            emailOther: dataFromStored?.emailOther || "",
            role: dataFromStored?.role || "",
            userId: dataFromStored?.userId || "",
            password: dataFromStored?.password || "",
            confirmPassword: dataFromStored?.confirmPassword || "",
            dateOfBirth:
              dataFromStored?.dateOfBirth != undefined
                ? formatDateDash(dataFromStored?.dateOfBirth)
                : dataFromStored?.birthday,
            gender: dataFromStored?.gender || "FEMALE",
          }}
          validate={(values) => {
            const errors = {};
            checkPassword(values.password);
            checkLength(values.password);
            if (!values.faculty) {
              errors.faculty = "ກະລຸນາເລືອກຄະນະ";
            }
            if (!values.department) {
              errors.department = "ກະລຸນາເລືອກພາກວິຊາ";
            }
            if (!values.role) {
              errors.role = "ກະລຸນາເລືອກລະດັບຜູ້ໃຊ້";
            }
            if (!values.firstname) {
              errors.firstname = "ກະລຸນາປ້ອນຊື່";
            }
            if (!values.lastname) {
              errors.lastname = "ກະລຸນາປ້ອນນາມສະກຸນ";
            }
            // if (!values.email) {
            //   errors.email = "ກະລຸນາຕື່ມອີເມວ";
            // } else if (
            //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
            // ) {
            //   errors.email = "ອີເມວບໍ່ຖືກຕ້ອງຕາມຮູບແບບ";
            // }
            if (!values.userId) {
              errors.userId = "ກະລຸນາປ້ອນໄອດີ";
            }
            if (teacherDataById === undefined && !values.password) {
              errors.password = "ກະລຸນາປ້ອນລະຫັດຜ່ານ";
            }
            if (teacherDataById === undefined && !values.confirmPassword) {
              errors.confirmPassword = "ກະລຸນາຍືນຍັນລະຫັດຜ່ານ";
            }
            if (
              values.confirmPassword &&
              values.confirmPassword !== values.password
            ) {
              errors.confirmPassword = "ລະຫັດບໍ່ຕົງກັນ";
            }
            return errors;
          }}
          onSubmit={(values) => {
            const combineData = {
              ...values,
              facultyName: selectFacultyName,
              departmentName: selectDepartmentName,
              tribeName: selectTribeName,
              religionName: selectReligionName,
            };
            // Store the serialized values in localStorage
            localStorage.setItem("generalInfo", JSON.stringify(combineData));
            history.push(Routers.TEACHER_FORM_STATISTIC, teacherDataById);
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            /* and other goodies */
          }) => (
            <div>
              {/* Form container */}
              <div
              // style={{
              //   width: "80%",
              //   marginLeft: "auto",
              //   marginRight: "auto",
              // }}
              >
                <Row className="d-flex" sm={12}>
                  {/* <Col sm={3}>
                    <FormLabel sx={{ color: 'black', fontSize: 20 }}>ເພີ່ມຂໍ້ມູນພ/ງ ຄູ-ອາຈານ</FormLabel>
                    <Box
                      width={170}
                      height={170}
                      sx={{
                        position: "relative",
                        backgroundColor: "lightgray",
                        border: '1px dashed grey',
                        borderRadius: '5px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <img
                        src={previewImageURL ? previewImageURL : '/empty-image64.png'}
                        alt='teacher image'
                      />
                    </Box>
                    <input
                      type="file"
                      id="inputImage"
                      multiple
                      name="image"
                      ref={inputImage}
                      style={{ display: "none" }}
                      onChange={handleChangeImage}
                    />
                    <div style={{
                      width: 170,
                      marginTop: '2px',
                      display: "flex",
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    >
                      <Button fullWidth
                        variant="contained"
                        onClick={addImage}
                        sx={{ textTransform: 'none' }}
                      >
                        Upload
                      </Button>
                    </div>
                    <p style={{ color: "red", fontSize: "12px", marginTop: "16px" }}>ໝາຍເຫດ: ກົດໃສ່ປຸ່ມສີຟ້າ ເພື່ອດຶງຮູບພາບທີ່ຕ້ອງການ</p>
                    {/* <p>ສ້າງໂດຍ: ກຸ້ງນາງ ຄໍາສິງສະຫວັດ</p>
                    <p>ແກ້ໄຂໂດຍ: ພິມພິກາ ອິນທະເສີດ</p>
                    <p>ວັນທີແກ້ໄຂ: 12/2/2024 - 10:00</p> */}
                  {/* </Col>  */}
                  <Col sm={12}>
                    <Form noValidate>
                      {/* ---------- ຂໍ້ມູນທົ່ວໄປ --------- */}
                      <div style={{ marginBottom: 10 }}>
                        <div className="heading">
                          <b>ຂໍ້ມູນທົ່ວໄປ</b>
                        </div>
                        <Row className="mb-1">
                          <Form.Group as={Col}>
                            <Form.Label>
                              ຊື່<span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="firstname"
                              value={values.firstname}
                              placeholder="ກະລຸນາປ້ອນຊື່"
                              onChange={handleChange}
                              isInvalid={!!errors.firstname}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.firstname}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>
                              ນາມສະກຸນ<span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="lastname"
                              value={values.lastname}
                              placeholder="ກະລຸນາປ້ອນນາມສະກຸນ"
                              onChange={handleChange}
                              isInvalid={!!errors.lastname}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.lastname}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>ວັນ ເດືອນ ປີ ເກີດ</Form.Label>
                            <Form.Control
                              type="date"
                              name="dateOfBirth"
                              value={values.dateOfBirth}
                              onChange={handleChange}
                              // isInvalid={!!errors.dateOfBirth}
                            />
                            {/* <Form.Control.Feedback type="invalid">
                              {errors.dateOfBirth}
                            </Form.Control.Feedback> */}
                          </Form.Group>
                        </Row>

                        <Form.Group
                          as={Row}
                          name="gender"
                          onChange={handleChange}
                        >
                          <Form.Label column sm={2} as="legend">
                            ເພດ
                          </Form.Label>
                          <Col
                            sm={10}
                            className="d-flex align-items-center gap-5"
                          >
                            <Form.Check
                              inline
                              type="radio"
                              label="ຍິງ"
                              name="gender"
                              value="FEMALE"
                              id="female"
                              checked={
                                values.gender === "FEMALE" ? true : false
                              }
                              onChange={handleChange}
                            />
                            <Form.Check
                              inline
                              type="radio"
                              label="ຊາຍ"
                              name="gender"
                              value="MALE"
                              id="male"
                              onChange={handleChange}
                              checked={values.gender === "MALE" ? true : false}
                            />
                          </Col>
                        </Form.Group>

                        <Row className="mb-1">
                          <Form.Group as={Col}>
                            <Form.Label>ສັນຊາດ</Form.Label>
                            <Form.Control
                              as="select"
                              placeholder=""
                              name="nationality"
                              value={values.nationality}
                              onChange={handleChange}
                              // isInvalid={!!errors.nationality}
                            >
                              <option value="">ເລືອກສັນຊາດ</option>
                              {COUNTRIES_LIST.map((item, index) => (
                                <option key={index} value={item.num_code}>
                                  {item.nationality}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>ຊົນເຜົ່າ</Form.Label>
                            <Form.Control
                              as="select"
                              placeholder=""
                              name="tribe"
                              value={values.tribe}
                              onChange={(e) => {
                                handleChange(e);
                                selectTribe(e);
                              }}
                              isInvalid={!!errors.tribe}
                            >
                              <option value="">ເລືອກຊົນເຜົາ</option>
                              {tribeData?.tribes.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.trib_name}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>ສາສະໜາ</Form.Label>
                            <Form.Control
                              as="select"
                              name="religion"
                              value={values.religion}
                              onChange={(e) => {
                                handleChange(e);
                                selectReligion(e);
                              }}
                              isInvalid={!!errors.religion}
                            >
                              <option value="">ເລືອກສາສະໜາ</option>
                              {religionalMastersData?.religionalMasters?.data?.map(
                                (item, index) => (
                                  <option key={index} value={item.id}>
                                    {item.name}
                                  </option>
                                )
                              )}
                            </Form.Control>
                          </Form.Group>
                        </Row>
                        <Row className="mb-1">
                          <Form.Group as={Col}>
                            <Form.Label>ເບີໂທ</Form.Label>
                            <Form.Control
                              type="text"
                              name="phone"
                              value={values.phone}
                              placeholder="020 xxx xxx xx"
                              onChange={handleChange}
                            />
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="email"
                              name="email"
                              value={values.email}
                              placeholder="acb@gmail.com"
                              onChange={handleChange}
                            />
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>ຊ່ອງທາງຕິດຕໍ່ອື່ນໆ</Form.Label>
                            <Form.Control
                              type="text"
                              name="emailOther"
                              value={values.emailOther}
                              placeholder=""
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Row>
                      </div>

                      {/* ---------- ສ້າງລະຫັດເພື່ອເຂົ້າໃຊ້ລະບົບ --------- */}
                      <div style={{ marginBottom: 10 }}>
                        <div className="heading">
                          <b>ສ້າງລະຫັດເພື່ອເຂົ້າໃຊ້ລະບົບ</b>
                        </div>
                        {/* ຊື່ */}
                        <Row className="mb-1">
                          <Form.Group as={Col}>
                            <Form.Label>ສິດການນໍາໃຊ້ລະບົບ</Form.Label>
                            <Form.Control
                              as="select"
                              name="role"
                              value={values.role}
                              onChange={handleChange}
                              isInvalid={!!errors.role}
                            >
                              <option value="">ເລືອກສິດການນຳໃຊ້ລະບົບ</option>
                              {ROLE_LIST.map((role, index) => (
                                <option value={role} key={index}>
                                  {convertRole(role)}
                                </option>
                              ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.role}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <Row className="mb-1">
                          <Form.Group as={Col}>
                            <Form.Label>ຄະນະ</Form.Label>
                            <Form.Control
                              as="select"
                              name="faculty"
                              value={values.faculty}
                              onChange={(e) => {
                                handleChange(e);
                                onSelectFaculty(e);
                              }}
                              isInvalid={!!errors.faculty}
                            >
                              <option value="">ເລືອກຄະນະ</option>
                              {dataFaculties.map((item, index) => (
                                <option value={item._id} key={index}>
                                  {item.name}
                                </option>
                              ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.faculty}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>ພາກສ່ວນສັງກັດ</Form.Label>
                            <Form.Control
                              as="select"
                              name="department"
                              value={values.department}
                              onChange={(e) => {
                                handleChange(e);
                                onSelectDepartment(e);
                              }}
                              isInvalid={!!errors.department}
                            >
                              <option value="">ເລືອກພະແນກ/ພາກວິຊາ</option>
                              {dataDepartment.map((item, index) => (
                                <option value={item._id} key={index}>
                                  {item.name}
                                </option>
                              ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.department}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <Row className="mb-1">
                          <Form.Group as={Col}>
                            <Form.Label>ໄອດີ</Form.Label>
                            <Form.Control
                              type="text"
                              name="userId"
                              value={values.userId}
                              placeholder="152026214"
                              onChange={handleChange}
                              isInvalid={!!errors.userId}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.userId}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        {/* {teacherDataById === undefined ? (
                          <> */}
                        <Row className="mb-1">
                          <Form.Group as={Col}>
                            <Form.Label>ລະຫັດຜ່ານ</Form.Label>
                            <Form.Control
                              type="text"
                              name="password"
                              value={values.password}
                              placeholder="***********"
                              onChange={handleChange}
                              isInvalid={!!errors.password}
                              disabled={teacherDataById !== undefined}
                            />
                            <div>
                              {/* <ProgressBar variant="progress-bar-low" now={100} /> */}
                              <Progress multi>
                                <Progress
                                  bar
                                  color="danger"
                                  value={strengthPass >= 25 ? 25 : 0}
                                >
                                  {strengthPass < 25 ? "" : "ຕ່ຳ"}
                                </Progress>
                                <Progress
                                  bar
                                  color="warning"
                                  value={strengthPass >= 50 ? 25 : 0}
                                >
                                  {strengthPass < 50 ? "" : "ກາງ"}
                                </Progress>
                                <Progress
                                  bar
                                  color="info"
                                  value={strengthPass >= 75 ? 25 : 0}
                                >
                                  {strengthPass < 75 ? "" : "ສູງ"}
                                </Progress>
                                <Progress
                                  bar
                                  color="success"
                                  value={strengthPass >= 100 ? 25 : 0}
                                >
                                  {strengthPass < 100 ? "" : "ສຸດຍອດ"}
                                </Progress>
                              </Progress>
                            </div>
                            <Form.Control.Feedback type="invalid">
                              {errors.password}
                            </Form.Control.Feedback>
                            {checkPasswordLength ? (
                              <p style={{ color: "red", fontSize: 12 }}>
                                {checkPasswordLength}
                              </p>
                            ) : (
                              ""
                            )}
                          </Form.Group>
                        </Row>
                        <Row className="mb-1">
                          <Form.Group as={Col}>
                            <Form.Label>ຢືນຢັນລະຫັດຜ່ານ</Form.Label>
                            <Form.Control
                              type="text"
                              name="confirmPassword"
                              value={values.confirmPassword}
                              placeholder="***********"
                              onChange={handleChange}
                              isInvalid={!!errors.confirmPassword}
                              disabled={teacherDataById !== undefined}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.confirmPassword}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        {/* </>
                        ) : (
                          <></>
                        )} */}
                      </div>
                    </Form>
                    {/* Buttons */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        textAlign: "center",
                        justifyContent: "end",
                        marginTop: 40,
                        marginBottom: 40,
                      }}
                    >
                      <Button
                        variant="contained"
                        endIcon={<KeyboardDoubleArrowRightIcon />}
                        onClick={handleSubmit}
                      >
                        ໜ້າຕໍ່ໄປ
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </Formik>
      </CustomContainer>
    </div>
  );
}

export default TeacherAdd;
