import React, { useState } from 'react'
import useReactRouter from 'use-react-router'
import { Col, Row, Modal, Button, Spinner } from 'react-bootstrap'
import { useMutation } from '@apollo/react-hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { UPDATE_STUDENT } from './apollo/mutation'
import { convertGender, convertStatus, onConvertEducationLevel, onConvertStudentInfo, convertGenderNamEN } from '../../helpers/user'
import 'bootstrap/dist/css/bootstrap.min.css'
import addLogs from '../../helpers/AddLogs'
import Routers from "../../consts/router";
// CourseAddConfirm
function RegistrationConfirmEn({ data, showAddConfirmModal, _handleShowAddConfirmModalClose }) {
	const { history } = useReactRouter()
	const [show, setShow] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [updateStudent] = useMutation(UPDATE_STUDENT)

	const _handleSubmit = async () => {
		try {
			setIsLoading(true)
			delete data.outPlanGraduateFromCountry
			delete data.birthCountry
			delete data.birthProvince
			delete data.country
			delete data.other
			delete data.parentCountry
			delete data.passport
			delete data.province
			delete data.qualification
			delete data.departmentname
			delete data.majorname
			delete data.facultyname
			delete data.idCardOrFamilyBook
			delete data.certificate
			delete data.testCard
			delete data.tribeName
			delete data.day;
			delete data.month;
			delete data.year;

			let _id = data.id
			delete data.id
			const updateStudented = await updateStudent({
				variables: {
					where: { id: _id },
					data
				}
			})

			await addLogs({
				action: "UPDATE",
				status: "SUCCESS",
				data: data,
				type: "PRE_STUDENT",
				student: _id
			})

			setIsLoading(false)
			setShow(true)
			_handleShowAddConfirmModalClose()
			if (updateStudented) {
				setTimeout(() => {
					history.push(Routers.PRERE_STUDENT_LIST)
					// history.push("/preregistration_student-detail", data);
					window.location.reload()
				}, 100)
			}
			setShow(false)
		} catch (err) {
			console.error('err:', err.message)
			setIsLoading(false)
		}
	}
	return (
		<div>
			<Modal
				style={{ marginTop: 50 }}
				show={showAddConfirmModal}
				onHide={() => isLoading ? console.log("waiting") : _handleShowAddConfirmModalClose()}
				size='lg'
				centered
				backdrop="static"
				keyboard={false}
			>
				<Modal.Header closeButton={!isLoading}>
					<Col
						style={{
							marginTop: 20,
							fontSize: 30,
							fontWeight: 'bold',
							color: '#057CAE'
						}}
					>
						Confirmation
					</Col>
				</Modal.Header>
				{isLoading ? <Modal.Body>
					<div style={{ width: "100%", minHeight: 350, display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
						<Spinner animation="border" style={{ color: "#057CAE" }} />
					</div>
				</Modal.Body> : <Modal.Body>
					<Row>
						<Col sm='2'></Col>
						<Col sm='8'>
							<Col style={{ fontSize: 24 }}>
								<FontAwesomeIcon
									icon={faCaretDown}
									style={{ color: '#057CAE' }}
								/>{' '}
								Student Information
							</Col>
							<Row style={{ marginLeft: 50 }}>
								<Col sm='6'>Registration Number</Col>
								<Col sm='6'>{data?.studentNumber}</Col>
								<Col sm='6'>Faculty</Col>
								<Col sm='6'>{data?.facultyname}</Col>
								<Col sm='6'>Department</Col>
								<Col sm='6'>{data?.departmentname}</Col>
								<Col sm='6'>Major</Col>
								<Col sm='6'>{data?.majorname}</Col>
								<Col sm='6'>Student's information</Col>
								<Col sm='6'>
									{data &&
										(data?.studentInfo
											? onConvertStudentInfo(data?.studentInfo)
											: '-')}
								</Col>
								<Col sm='6'>Level</Col>
								<Col sm='6'>
									{data &&
										(data?.educationLevel
											? onConvertEducationLevel(data?.educationLevel)
											: '-')}
								</Col>
							</Row>
							<Col style={{ fontSize: 24 }}>
								<FontAwesomeIcon
									icon={faCaretDown}
									style={{ color: '#057CAE' }}
								/>{' '}
								General information
							</Col>
							<Col className='row' style={{ marginLeft: 38 }}>
								<Col sm='6'>Given name</Col>
								<Col sm='6'>{convertGenderNamEN(data?.gender)} {data?.firstNameE}</Col>
								<Col sm='6'>Family name</Col>
								<Col sm='6'>{data?.lastNameE}</Col>
								<Col sm='6'>Date of birth</Col>
								<Col sm='6'>{data?.birthday}</Col>
								<Col sm='6'>Gender</Col>
								<Col sm='6'>
									{data &&
										(data?.gender ? convertGender(data?.gender) : '-')}
								</Col>
								<Col sm='6'>Marital status</Col>
								<Col sm='6'>
									{data &&
										(data?.maritualStatus
											? convertStatus(data?.maritualStatus)
											: '-')}
								</Col>
								<Col sm='6'>nationality</Col>
								<Col sm='6'>{data?.nationality}</Col>
								<Col sm='6'>Religion</Col>
								<Col sm='6'>{data?.religion}</Col>
								<Col sm='6'>Phone home</Col>
								<Col sm='6'>{data?.phoneHome}</Col>
								<Col sm='6'>Telephone number</Col>
								<Col sm='6'>{data?.phoneMobile}</Col>
								<Col sm='6'>Email</Col>
								<Col sm='6'>{data?.email}</Col>
							</Col>
							<Col style={{ fontSize: 24 }}>
								<FontAwesomeIcon
									icon={faCaretDown}
									style={{ color: '#057CAE' }}
								/>{' '}
								Current resident
							</Col>
							<Row style={{ marginLeft: 50 }}>
								<Col sm='6'>Country</Col>
								<Col sm='6'>{data?.address?.country}</Col>
								<Col sm='6'>Province</Col>
								<Col sm='6'>{data?.address?.province}</Col>
								<Col sm='6'>District</Col>
								<Col sm='6'>{data?.address?.district}</Col>
								<Col sm='6'>Village</Col>
								<Col sm='6'>{data?.address?.village}</Col>
							</Row>
							<Col style={{ fontSize: 24 }}>
								<FontAwesomeIcon
									icon={faCaretDown}
									style={{ color: '#057CAE' }}
								/>{' '}
								Birthplace
							</Col>
							<Col className='row' style={{ marginLeft: 38 }}>
								<Col sm='6'>Country</Col>
								<Col sm='6'>{data?.birthAddress?.country}</Col>
								<Col sm='6'>Province</Col>
								<Col sm='6'>{data?.birthAddress?.province}</Col>
								<Col sm='6'>District</Col>
								<Col sm='6'>{data?.birthAddress?.district}</Col>
								<Col sm='6'>Village</Col>
								<Col sm='6'>{data?.birthAddress?.village}</Col>
							</Col>
							{data?.studentType === 'OUT_PLAN_EN' ? (
								<div>
									<Col style={{ fontSize: 24 }}>
										<FontAwesomeIcon
											icon={faCaretDown}
											style={{ color: '#057CAE' }}
										/>{' '}
										For self-financing student
									</Col>
									<Row style={{ marginLeft: 50 }}>
										<Col sm='6'>Country</Col>
										<Col sm='6'>{data?.graduateFromCountry}</Col>
										<Col sm='6'>Province</Col>
										<Col sm='6'>{data?.graduateFromProvince}</Col>
										<Col sm='6'>Graduated from</Col>
										<Col sm='6'>{data?.graduateFromSchool}</Col>
										<Col sm='6'>Academic year</Col>
										<Col sm='6'>{data?.semesterYear}</Col>
									</Row>
								</div>
							) : (
								<div>
									<Col style={{ fontSize: 24 }}>
										<FontAwesomeIcon
											icon={faCaretDown}
											style={{ color: '#057CAE' }}
										/>{' '}
										For student/Civil servant who receives scholarship from an organization
									</Col>
									<Row style={{ marginLeft: 50 }}>
										<Col sm='6'>Scholarship</Col>
										<Col sm='6'>{data?.scholarship}</Col>
										{/* <Col sm='6'> ເລກທີໃບນຳສົ່ງ</Col>
                    <Col sm='6'>{data?.transferLetterNo}</Col>
                    <Col sm='6'>ລົງ​ວັນ​ທີ່</Col>
                    <Col sm='6'>{data?.dateTransferLetterNo}</Col>
                    <Col sm='6'>ເລກທີຂໍ້ຕົກລົງເສັງ​ໄດ້​ທຶນ ມ​ຊ</Col>
                    <Col sm='6'>{data?.scholarshipAgreementNo}</Col>
                    <Col sm='6'>ລົງ​ວັນ​ທີ່</Col>
                    <Col sm='6'>{data?.dateScholarshipAgreementNo}</Col> */}
										<Col sm='6'>Country</Col>
										<Col sm='6'>{data?.graduateFromCountry}</Col>
										<Col sm='6'>Province</Col>
										<Col sm='6'>{data?.graduateFromProvince}</Col>
										<Col sm='6'>Graduated from</Col>
										<Col sm='6'>{data?.graduateFromSchool}</Col>
										<Col sm='6'>Academic year</Col>
										<Col sm='6'>{data?.semesterYear}</Col>
									</Row>
								</div>
							)}
							{data?.educationLevel === "BACHELOR_CONTINUING" ? (
								<Row>
									<Col>Graduated major</Col>
									<Col>{data?.graduatedMajor}</Col>
								</Row>
							) : null}

							<Col style={{ fontSize: 24 }}>
								<FontAwesomeIcon
									icon={faCaretDown}
									style={{ color: '#057CAE' }}
								/>{' '}
								Parent information
							</Col>
							<Row style={{ marginLeft: 50 }}>
								<Col sm='6'>Father name</Col>
								<Col sm='6'>{data?.fatherNameAndSurname}</Col>
								<Col sm='6'>Mothername</Col>
								<Col sm='6'>{data?.motherNameAndSurname}</Col>
								<Col sm='6'>Phone number</Col>
								<Col sm='6'>{data?.parentTelephone}</Col>
								<Col sm='6'>Country</Col>
								<Col sm='6'>{data?.parentAddress?.country}</Col>
								<Col sm='6'>Province</Col>
								<Col sm='6'>{data?.parentAddress?.province}</Col>
								<Col sm='6'>District</Col>
								<Col sm='6'>{data?.parentAddress?.district}</Col>
								<Col sm='6'>Village</Col>
								<Col sm='6'>{data?.parentAddress?.village}</Col>
							</Row>
							<Col style={{ fontSize: 24 }}>
								<FontAwesomeIcon
									icon={faCaretDown}
									style={{ color: '#057CAE' }}
								/>{' '}
								Emergency contact
							</Col>
							<Row style={{ marginLeft: 50 }}>
								<Col sm='6'>Name</Col>
								<Col sm='6'>{data?.contactName}</Col>
								<Col sm='6'>Phone number</Col>
								<Col sm='6'>{data?.emergencyPhone}</Col>
								<Col sm='6'>Same address</Col>
								<Col sm='6'>{data?.stayTogether === true ? "YES" : "NO"}</Col>
								{data?.stayTogether === false ? (
									<>
										<Col sm='6'>Emergency country</Col>
										<Col sm='6'>{data?.emergencyAddress?.country}</Col>
										<Col sm='6'>Emergency province</Col>
										<Col sm='6'>{data?.emergencyAddress?.province}</Col>
										<Col sm='6'>Emergency district</Col>
										<Col sm='6'>{data?.emergencyAddress?.district}</Col>
										<Col sm='6'>Emergency village</Col>
										<Col sm='6'>{data?.emergencyAddress?.village}</Col>
									</>
								) : null}

							</Row>
							<Col style={{ fontSize: 24 }}>
								<FontAwesomeIcon
									icon={faCaretDown}
									style={{ color: '#057CAE' }}
								/>{' '}
								Attach files
							</Col>
							<Row style={{ marginLeft: 50 }}>
								<Col sm='6'>National ID or passport</Col>
								<Col
									sm='6'
									style={{
										overflowX: 'hidden',
										overflowY: 'hidden',
										whiteSpace: 'nowrap',
										textOverflow: 'ellipsis'
									}}
								>
									{data?.passportUrl?.url ?? "-"}
								</Col>
								<Col sm='6'>Graduation certificate</Col>
								<Col
									sm='6'
									style={{
										overflowX: 'hidden',
										overflowY: 'hidden',
										whiteSpace: 'nowrap',
										textOverflow: 'ellipsis'
									}}
								>
									{data?.qualificationUrl?.url ?? "-"}
								</Col>
								{/* <Col sm='6'>ໃບປະກາດ</Col>
                <Col
                  sm='6'
                  style={{
                    overflowX: 'hidden',
                    overflowY: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {data?.testCard}
                </Col> */}
								<Col sm='6'>Other</Col>
								<Col
									sm='6'
									style={{
										overflowX: 'hidden',
										overflowY: 'hidden',
										whiteSpace: 'nowrap',
										textOverflow: 'ellipsis'
									}}
								>
									{data?.otherUrl?.url ?? "-"}
								</Col>
							</Row>
						</Col>
					</Row>
					<Col
						className='d-flex justify-content-center'
						style={{ marginTop: 50, marginBottom: 30 }}
					>
						<Col sm='3'>
							<button
								type='submit'
								style={{
									width: 122,
									height: 48,
									color: '#057CAE',
									border: '2px solid #057CAE',
									backgroundColor: '#fff',
									borderRadius: 6
								}}
								onClick={() => _handleShowAddConfirmModalClose()}
							>
								Cancel
							</button>
						</Col>
						<Col sm='1' />
						<Col sm='3'>
							<Button
								// type='submit'
								onClick={() => {
									_handleSubmit()
								}}
								style={{
									width: 122,
									height: 48,
									color: '#fff',
									backgroundColor: '#057CAE',
									borderRadius: 6
								}}
							>
								Confirm
							</Button>
						</Col>
					</Col>
				</Modal.Body>}
			</Modal>
			<Modal show={show} onHide={() => setShow(false)} centered>
				<Modal.Body>
					<Col
						className='text-center'
						style={{ paddingTop: 40, paddingBottom: 50 }}
					>
						<div
							className='text-bold'
							style={{ color: '#00A1DE', fontSize: 32 }}
						>
							ສໍາເລັດ
						</div>
						<FontAwesomeIcon
							icon={faCheckCircle}
							size='9x'
							style={{ color: '#00A1DE' }}
						/>
						<div
							className='textfont-size19 text-bold'
							style={{ marginTop: 40 }}
						>
							ປັນທືກເປັນທີ່ຮຽນຮ້ອຍ
						</div>
					</Col>
				</Modal.Body>
			</Modal>
		</div>
	)
}

export default RegistrationConfirmEn
