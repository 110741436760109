import { gql } from "apollo-boost";

export const FACULTIES = gql`
  query Faculties($where: CustomFacultyWhereInput){
    faculties(
      where: $where
    ){
      id
      name
      departments{
        id
        name
      }
    }
  }
`
export const DEPARTMENTS = gql`
  query Departments($where: CustomDepartmentWhereInput){
    departments(
      where: $where
    ){
      id
      name
    }
  }
`
export const MAJORS = gql`
  query Majors($where: CustomMajorWhereInput){
    majors(
      where: $where
    ){
      data{
        id
        name
      }
    }
  }
`
export const STUDENTS = gql`
query PreregistrationStudents(
        $where: PreregistrationStudentWhereInput
        $skip: Int
        $first: Int
        $orderBy: PreregistrationStudentOrderByInput) {
      preregistrationStudents(
      where: $where
      skip: $skip
      first: $first
      orderBy: $orderBy) {
        total
		female
		male
		inPlan
		outPlan
        data{
          id
          studentNumber
          examCode
          faculty{
            id
            name
          }
          department{
            id
            name
          }
          major{
            id
            name
          }
          code
          studentInfo
          statusStep
          educationLevel
          firstNameL
          maritualStatus
          lastNameL
          firstNameE
          lastNameE
          birthday
          gender
          nationality
          tribe{
            id
            trib_name
          }
          religion
          phoneHome
          phoneMobile
          email
          address{
            country
            province
            district
            village
          }
          bornAbroad
          birthAddress{
            country
            province
            district
            village
          }
          scholarship
          otherScholarship
          scholarshipFrom
          assessmentYear
          graduateFromCountry
          graduateFromProvince
          graduateFromSchool
          semesterYear
          graduatedMajor
          contactName
          emergencyPhone
          stayTogether
          explainNotToStayTogether
          emergencyAddress{
            country
            province
            district
            village
          }
          imageProfile
          studentType
          createdAt
          createdBy
          updatedAt
          updatedBy
          adminApprovedBy
          facultyApprovedBy
          note
          fatherNameAndSurname
          motherNameAndSurname
          parentTelephone
          dormitory
          dateTransferLetterNo
          transferLetterNo
          scholarshipAgreementNo
          dateScholarshipAgreementNo
          parentAddress{
            country
            province
            district
            village
          }
          idCardOrFamilyBookUrl{
            url
          }
          scorecardUrl{
            url
          }
          certificateUrl{
            url
          }
          testCardUrl{
            url
          }
          passportUrl{
            url
          }
          qualificationUrl{
            url
          }
          otherUrl{
            url
          }
          comment
          rejectBy
          healthStatus
          health
        }
    }
  }
`

export const TRIBES = gql`
  query Tribes($where: tribeInput){
    tribes(where: $where){
      id
      trib_id
      trib_name
      tribNo
    }
  }
`;

export const CODE = gql`
query Code($where: LoginInput! ){
    code(where: $where){
      id
      code
      firstNameL
      lastNameL
      firstNameE
      lastNameE
      faculty{
        id
        name
      }
      department{
        id
        name
      }
      major{
        id
        name
      }
      gender
      birthday
      phoneMobile
      contactName
      emergencyPhone
      studentType
    }
  }
`
export const PREREGISTRATION_TRIBE = gql`
query PreregistrationStudentTribes($where: WhereStudentTribeInput) {
  preregistrationStudentTribes(where: $where) {
    total
    data{
      name
      male
      female
      count
    }
  }
}
`
export const PREREGISTRATION_DEPARTMENT = gql`
query PreregistrationStudentDepartment($where: WhereStudentDepartmentInput) {
  preregistrationStudentDepartment(where: $where) {
    name
    countDepartment
    requestCount
    approvedCount
  }
}
`

export const PREREGISTRATION_STATISTIC = gql`
query PreregistrationStudentStatistics($where: WhereStudentStatisticsInput) {
  preregistrationStudentStatistics(where: $where) {
    total
    approvedCounts
    requestCounts
  	data{
      name
      count
      request
      approved
    }
  }
}
`

export const USER = gql`
  query User($where: UserWhereUniqueInput!) {
    user(where: $where) {
      id
      userId
      firstname
      lastname
    }
  }
`;
