import React, { useState } from "react";
import ScholarshipAndJob from "./scholarshipAndJob";
import LaoDoc from "./LaoDoc";
import Popup from "./Popup";
import PasswordModal from "../../common/PasswordModal";

const TabComponent = () => {
  const [activeTab, setActiveTab] = useState("scholarship");
  const [passwordModalOpen, setPasswordModalOpen] = useState(true);
  const [passwordAttempts, setPasswordAttempts] = useState(0);

  const renderContent = () => {
    switch (activeTab) {
      case "scholarship":
        return <ScholarshipAndJob />;
      case "laodoc":
        return <LaoDoc />;
      case "popup":
        return <Popup />;
      default:
        return <ScholarshipAndJob />;
    }
  };

  const handlePasswordValidate = (isValid) => {
    if (!isValid) {
      setPasswordAttempts((prev) => prev + 1);
      setPasswordModalOpen(false);
    }
  };

  return (
    <div>
      <PasswordModal
        open={passwordModalOpen}
        onClose={() => setPasswordModalOpen(false)}
        onValidate={handlePasswordValidate}
      />

      <ul className="nav nav-tabs">
        <li className="nav-item">
          <button
            className={`nav-link ${
              activeTab === "scholarship" ? "active" : ""
            }`}
            onClick={() => setActiveTab("scholarship")}
          >
            ScholarshipJob
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === "laodoc" ? "active" : ""}`}
            onClick={() => setActiveTab("laodoc")}
          >
            LaoDoc
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === "popup" ? "active" : ""}`}
            onClick={() => setActiveTab("popup")}
          >
            Popup
          </button>
        </li>
      </ul>
      <div className="tab-content mt-3">{renderContent()}</div>
    </div>
  );
};

export default TabComponent;
