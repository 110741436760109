import { createSlice } from "@reduxjs/toolkit";
import { USER_KEY } from "../consts";
const user = localStorage.getItem(USER_KEY);
const userObject = JSON.parse(user);

console.log("")

export const filterSlice = createSlice({
  name: "accessmentYear",
  initialState: {
    faculty:
      userObject?.data?.role !== "ADMIN" ? userObject?.data?.faculty?.id : "",
    department:
      userObject?.data?.role !== "ADMIN"
        ? userObject?.data?.department?.id
        : "",
    major: "",
    educationLevel: "",
  },
  reducers: {
    setFilter: (state, action) => {
      state.faculty = action.payload.faculty;
      state.department = action.payload.department;
      state.major = action.payload.major;
      state.educationLevel = action.payload.educationLevel;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setFilter } = filterSlice.actions;

export default filterSlice.reducer;
