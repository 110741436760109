import React from "react";
import { Modal, Form } from "react-bootstrap";
import { Formik } from "formik";
import { CustomButton } from "../../common";

const NoticeSearch = ({ showSearchView, _handleSearchViewClose, onSearch }) => {
  return (
    <Formik
      initialValues={{
        keyword: "",
      }}
      validate={(values) => {}}
      onSubmit={(values) => {
        onSearch(values);
        // _handleSearchViewClose();
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Modal show={showSearchView} onHide={_handleSearchViewClose} size="lg">
          <Modal.Title style={{ textAlign: "center", paddingTop: 20 }}>
            ຄົ້ນຫາແຈ້ງການ
          </Modal.Title>

          <Modal.Body style={{ marginLeft: 50, marginRight: 50, padding: 50 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ marginRight: 20, fontWeight: "bold" }}>ຄຳສັບ</div>
              <div style={{ width: "100%" }}>
                <Form.Control
                  type="text"
                  placeholder="ຫົວຂໍ້"
                  name="keyword"
                  value={values.keyword}
                  onChange={handleChange}
                  style={{
                    width: "100%",
                    height: 35,
                    borderRadius: 15,
                    outline: "none",
                    borderColor: "1px solid #eee",
                  }}
                />
              </div>
            </div>
            <div style={{ height: 20 }} />
            <div className="row">
              <div style={{ padding: 15 }} className="col">
                <CustomButton
                  confirm
                  onClick={handleSubmit}
                  width="100%"
                  title="ຄົ້ນຫາ"
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </Formik>
  );
};

export default NoticeSearch;
